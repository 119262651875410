function LandingScreen() {

  return (
    <div class='wrapper-landing-screen'>

      <section class='flex-column hero'>

        <nav class='flex-column landing-main-nav'>
          <div class='container-primary'>
            <div class='container-links'>
                <span>BETA</span>
                <a href='/login'>Login</a>
            </div>
          </div>
        </nav>

        <div class='container-primary container-hero'>

          <div class='logo'></div>

          <h1>
            Organize your
            <br />
            <strong>Daily Standup</strong>
          </h1>

          <a href='/register'>Get Started</a>
        </div>

      </section>


      <section class='flex-column section-landing-primary'>

        <div class='flex-column container-primary'>

          <div class='flex-row'>

            <div>
              <p class='sub-heading'>Plan your day</p>
              <h2>Check 'em off as you go</h2>
            </div>

            <div class='today-image'></div>

          </div>
        </div>

      </section>


      <section class='flex-column section-landing-primary'>

        <div class='flex-column container-primary'>

          <div class='flex-row'>

          <div class='week-image'></div>

            <div>
              <p class='sub-heading'>Think ahead</p>
              <h2>Plan your week by adding tasks as you think of them</h2>
            </div>

          </div>
        </div>

      </section>


      <section class='flex-column section-landing-secondary'>

        <div class='flex-column container-primary'>

          <p>Free while in Beta</p>
          <a href='/register' class='big-action-button'>Get Started</a>

          <a
            class='secondary-link'
            href='mailto:diastandup@gmail.com'
            style={{margin: '60px 0'}}
          >diastandup@gmail.com</a>

        </div>
      </section>



      <section class='flex-column section-landing-primary'>

        <div class='flex-column container-primary'>

          <div class='flex-row' style={{width: '100%'}}>

            <div>
              <h2>Tell me more</h2>

              <p>&nbsp;</p>
              <p class='sub-heading'>So, what actually is Dia Daily Standup?</p>
              <p>Dia is a simple and strightfoward task manager. You can add the tasks you need to work on Today, tomorrow, and throughout the week ( and even into next week ).</p>

              <p>&nbsp;</p>
              <p class='sub-heading'>What is a daily standup?</p>
              <p>A <a href='https://www.atlassian.com/agile/scrum/standups' target="_blank" rel='noreferrer'>daily standup</a> is a short, daily meeting to discuss progress and identify blockers with your team. Each person typcially tells what the accomplished yesterday, what they will be working on today, and what blockers they have.</p>

              <p>&nbsp;</p>
              <p class='sub-heading'>Do I need to do daily standups to use Dia?</p>
              <p>Nope. You can use Dia simply as an easy task managment tool.</p>
              <p>If you do daily standups, there's a handy feature to copy your standup tasks so you can paste them into Slack or some other message tool, if you use one of those to report your standup instead of doing it in person.</p>

              <p>&nbsp;</p>
              <p class='sub-heading'>What's this "push to tomorrow" thing?</p>
              <p>So you added some tasks to your day, but you weren't able to finish all of them. Use the "push to tomorrow" button to push that task to the next day, so it's ready and waiting for you tomorrow.</p>

              <p>&nbsp;</p>
              <p class='sub-heading'>Why Dia? Why shouldn't I use some other task manager?</p>
              <p>It's true, there are bunches of task managers out there. But most them have tons of features and bells and whistles and cost a decent amount. Dia is simple by design and meant to keep you on track without much setup or work on your part.</p>

            </div>


          </div>
        </div>

      </section>

    </div>
  )
}

export default LandingScreen;